import React from 'react'
import './register.scss'
import './mobile.scss'
import Navbar from '../../components/navbar/Navbar'

const register = () => {
  return (
    <div className='register'>
        <div className="contactBanner">
            <Navbar/>
            <div className="conTitile">
             <h2>Join ICYE NIG</h2>
            </div>
        </div>
        <div className="contactBg">
            <div className="address">
                <div className="elegible">
                    <h2>ELIGIBLE EXCHANGEE CANDIDATE</h2>
                    <p>ICYE Nigeria can send and receive exchangees/applicants from the following countries. (click here to see our partners) Interested In-Coming volunteer must be aged between 18 - 35years to participate in the exchange programme while Out-Going must be aged between 18 and 27 years of age.</p>
                </div>
                <div className="elegible">
                    <h2>BECOME A MEMBER - PARTICIPATE IN THE ICYE PROGRAMME</h2>
                    <p>
                        <ul>
                            <li>1. Open minded and self motivated</li>
                            <li>2. Willing to work voluntarily</li>
                            <li>3. Able to communicate and understand English language</li>
                            <li>4. Mature enough to get along with others</li>
                            <li>5. Willing to adapt to cultural differences</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className="contactForm">
                <div className="formBrag">
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='First Name' name=''/>
                        </div>
                    </div>
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='First Last' name=''/>
                        </div>
                    </div>
                </div>
                <div className="formBrag">
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='Email' name=''/>
                        </div>
                    </div>
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='Contact Phone' name=''/>
                        </div>
                    </div>
                </div>
                <div className="formBrag">
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='L.G.A' name=''/>
                        </div>
                    </div>
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='State' name=''/>
                        </div>
                    </div>
                </div>
                <div className="formBrag">
                    <div className="formtextarea">
                        <textarea placeholder='Message...' name=''></textarea>
                    </div>
                </div>
                <div className="formBrag">
                    <button>Register Now</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default register