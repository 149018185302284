import React from 'react'
import { Link } from 'react-router-dom'
import "./hero.scss"

const HeroTitle = () => {
  return (
    <div className="container">
        
        <div className="heroSec">
            <div className="wel">Welcome to ICYE Nigeria</div>
            <h1>A unique way to challenge and experience cultural</h1>
            <div className="contactButton">
              <Link to='/Register'><button className='vol'>Volunteering</button></Link>
              <Link to='/Contact'><button className='cons'>Contact Us</button></Link>
              
            </div>
        </div>
      </div>
  )
}

export default HeroTitle