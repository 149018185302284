import React from 'react'
import "./gallery.scss"
import "./mobile.scss"
import sl1 from "../../images/sl1.jpg"
import sl2 from "../../images/sl2.jpg"
import sl3 from "../../images/sl3.jpg"
import whoweare from "../../images/whoweare.png"

const Gallery = () => {
  return (
    <div className='gallery'>
        <div className="galleryBox">
            <div className="galleryTitle">
                Events
                <h1>Gallery</h1>
            </div>
            <div className="pixcontaner">
                <div className="pic">
                    <div className="titlePix">Best Friend</div>
                    <img src={sl1} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Best Friend</div>
                    <img src={sl2} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Best Friend</div>
                    <img src={sl3} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Best Friend</div>
                    <img src={whoweare} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Gallery