import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import "./contact.scss"
import "./mobile.scss"
//import PersonIcon from '@mui/icons-material/Person';
//import MenuIcon from '@mui/icons-material/Menu';

const Contact = () => {
  return (
    <div className='contact'>
        <div className="contactBanner">
            <Navbar/>
            <div className="conTitile">
             <h2>Contact ICYE NIG</h2>
            </div>
        </div>
        <div className="contactBg">
            <div className="address">
            <iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=100%&amp;height=300&amp;hl=en&amp;q=318,%20HERBERT%20MACAULAY%20STREET,%20SABO%20YABA,%20LAGOS.%20Lagos+(ICYE%20NIG)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

            </div>
            <div className="contactForm">
                <div className="formBrag">
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='First Name' name=''/>
                        </div>
                    </div>
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='First Last' name=''/>
                        </div>
                    </div>
                </div>
                <div className="formBrag">
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='Email' name=''/>
                        </div>
                    </div>
                    <div className="formLeft">
                        <div className="icons"></div>
                        <div className="iconsField">
                            <input type='text' placeholder='Contact Phone' name=''/>
                        </div>
                    </div>
                </div>
                <div className="formBrag">
                    <div className="formtextarea">
                        <textarea placeholder='Message...' name=''></textarea>
                    </div>
                </div>
                <div className="formBrag">
                    <button>Send Message</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact