import React from 'react'
import "./who.scss"

const Who = () => {

  return (
    <div className='who'>
      <h2>Who we are</h2>
      <h1>
        We are a global community of youth from different cultural
      </h1>
      <div className="boxFram">
         <div className="bxF">
            <div className="titl">WHAT WE DO</div>
            <p>We expose people to a world outside of their own. 
              Everyone has the chance to participate and benefit from ICYE’s 
              cross-cultural, humanitarian, and peace-building programs.</p>
         </div>
         <div className="fbook">
          <div className="fbTitle">ICYE Projects and Activities</div>
         <div class="fb-page" data-href="https://web.facebook.com/icyenigeria.org" data-tabs="timeline" data-width="500" data-height="500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://web.facebook.com/icyenigeria.org" class="fb-xfbml-parse-ignore"><a href="https://web.facebook.com/icyenigeria.org">ICYE Nigeria Projects and Activities</a></blockquote></div>
         </div>
      </div>
    </div>
  )
}

export default Who