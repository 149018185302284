import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar'
import "./about.scss"
import "./mobile.scss"

const About = () => {
  return (
    <div className='about'>
      <div className="contactBanner">
            <Navbar/>
            <div className="conTitile">
             <h2>About ICYE NIG</h2>
            </div>
      </div>
      <div className="aboutBg">
      <div className="who">
        <div className="aboutLeft">
            <h2>Who We Help</h2>
            <p>ICYE exposes people to a world outside of their own. Everyone has 
              the chance to participate and benefit from ICYE's cross-cultural, 
              humanitariam, and peace-building programs. We support an enormous 
              network of school, NGOs, Orphanages, Hospital, Clinics, community 
              development projects, national parks and more by providing essential 
              volunteers, conducting special projects, and fostering a global 
              understanding.</p>
              <h2>Federation's</h2>
              <b>70+ years of Global Citizenship and Hands-on Volunteering</b>
              <hr/>
              <p>ICYE provides intercultural learning experiences and opportunities 
                in more than 40 countries, especially supporting young people in 
                building personal and professional competencies through international volunteering. 
                Together with the volunteers and the communities, 
                we promote global solidarity, social justice, gender equality, 
                peace and environmental sustainability.</p>
                <p>
                  <Link to='https://www.icye.org/'>Federation's website</Link></p>
                <hr/>
          </div>
          <div className="aboutRight">
            <div className="aboutTop">
              <h3>ICYE Mission</h3>
              <ul>
                <li>To provide challenging intercultural learning experiences for young people</li>
                <li>To promote social and interpersonal development through international volunteer programmes</li>
                <li>To promote intercultural understanding, equality of opportunity, tolerance and peace among people in the world</li>
              </ul>
            </div>
            <div className="aboutBootm">
              <h3>ICYE Goals</h3>
              <p>ICYE is committed to and will work with others who are committed to:</p>
              <ul>
                <li>Justice for all persons who suffer from social, political, economic and personal injustice;</li>
                <li>Break through the barriers between cultures</li>
                <li>The principal of equality among cultures, nations and sexes</li>
                <li>Providing experiences that will encourage international and intercultural understanding</li>
                <li>Providing experiences that will encourage international and intercultural understanding</li>
                <li>Helping participants to become aware of the issues and problems in the local and international community in order to better understand worldwide socio-economic political issues and problems</li>
                <li>Encouraging commitment to act on these understandings, both during the exchange experience and beyond.</li>
              </ul>
            </div>
          </div>
      </div>
      <div className="objective">
        <div className="obLeft"></div>
        <div className="obRight"></div>
      </div>
      
        
      </div>
    </div>
  )
}

export default About