import React from 'react'
import "./mobile.scss"
import "./subscrib.scss"

const Subscrib = () => {
  return (
    <div className='Sub'>
        <div className="signup">
            <h2>Sign Up For Our newsletter</h2>
            Be amongst the first to get exciting new updates from us.
        </div>
        <div className="signForm">
            <input type="text" placeholder='Enter your email' />
            <button>Subscrib Now</button>
        </div>
    </div>
  )
}

export default Subscrib