import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import "./icyenig.scss"
import "./mobile.scss"

const IcyeNig = () => {
  return (
    <div className='icyeNig'>
        <div className="contactBanner">
            <Navbar/>
            <div className="conTitile">
             <h2>ICYE Nigeria Is A Legally Registered 
                Non Governmental Organisation (NGO)</h2>
            </div>
        </div>
        <div className="iceyBg">
            <div className="iceyLeft">
                <h2>Organization</h2>
                <p>ICYE Nigeria is a legally registered Non Governmental 
                    Organisation (NGO) which started operations in 1982 as 
                    an independent establishment within a Christian organization 
                    in Lagos, Nigeria. ICYE Nigeria consists of a National 
                    Committee office with Staff and Co-workers 
                    (Co-workers are made of up returnees -out-going 
                    volunteers- who have gone on the exchange programme 
                    following their consistent commitment to the work of 
                    ICYE in the National office).
                </p>
                <p>
                <div className='bgInsidePage'>
                ICYE Nigeria aspires towards the development of the leadership 
                of young people through living and working abroad between 
                six months to one year. The exposure and experience of these 
                young people as volunteers in a different environment and 
                culture is of great benefit to them. These benefits include 
                meeting new people, learning new language and culture, 
                developing skills based on working environment.</div>
                </p>
            </div>
            <div className="iceyRight">
                <h2>Country</h2>
                <p>
                ICYE Nigeria hosts In-Coming Exchangees (volunteers) from other
                 Countries as well as sends Out –Going Exchangees (volunteers) 
                 from Nigeria to other countries. Every year Nigerian host 
                 families and special work communities receive our foreign 
                 volunteers. Our host families and special work communities 
                 are individuals/organisations who are interested in foreign 
                 cultures and have an interest in social development projects 
                 within their communities.
                </p>
                <p>
                As an In-Coming or Out-Going Exchangee, volunteering outside 
                your country with an open mind and having the ability to 
                live with and understand people who have a different 
                perspective from yours is very important for a fruitful and 
                enjoyable stay during the period. Living in another culture 
                might not be easy but with a deep commitment and the effort 
                of the ICYE Nigeria staff, you are sure to have a rewarding 
                experience.
                </p>
            </div>
        </div>
    </div>
  )
}

export default IcyeNig