import React from 'react'
import { Link } from 'react-router-dom'
import "./become.scss"
import "./mobile.scss"

const Become = () => {
  return (
    <div className='Bcom'>
      <div className="textDetail">
        <div className="txt">
          <h2>Become a member of ICYE Nigeria</h2>
          <h1>EXPERIENCE EXCITING CULTURES</h1>
          <p>Discover, understand, and appreciate other nation's culture, heritage, daily life and 
            struggles on a deeper and more personal level</p>
        </div>
        <div className="contactButton">
            <Link to='/Register'><button className='vol'>Register Now</button></Link>
            <Link to='/Programmes'><button className='cons'>View programmes</button></Link>     
        </div>
      </div>
    </div>
  )
}

export default Become