import React, { useState } from 'react'
import "./navbar.scss"
import "./mobile.scss"
import { Link } from "react-router-dom"
import icye from "../../images/icye.png"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
//import InstagramIcon from '@mui/icons-material/Instagram';
//import FacebookIcon from '@mui/icons-material/Facebook';
//import TwitterIcon from '@mui/icons-material/Twitter';


const Navbar = () => {
    
    const [click,setClick] = useState(false);

  return (
    <div className='navbar'>
        <div className="top">
            <div className="logo">
                <div className="orgLogo"><img src={icye} alt='ICYE NIG' /></div>
                <div className="orgName">
                    <div className="logoName">ICYE NIGERIA</div>
                    <div className="slug">International Cultural</div>
                </div>
            </div>
            <div className="media">Tel: (+234) 802 856 1218</div>
        </div>
        <div className="menuBar">
            <ul className={click ? "mobile-link" : "nav-link"} onClick={()=> setClick(false)}>
            <Link to="/" className='links'><li>Home</li></Link>
            <Link to="/About" className='links'><li>who we are</li></Link>
            <Link to="/IcyeNig" className='links'><li>icye Nig</li></Link>
            <Link to="/Nigeria" className='links'><li>country profile</li></Link>
            <Link to="/Programmes" className='links'><li>program</li></Link>
            <Link to="/" className='links'><li>partners</li></Link>
            <Link to="/" className='links'><li>Host projects</li></Link>
            </ul>
            <div className="getNot">
                <Link to='/Register' className='links' style={{color:'#fff'}}>Register Now</Link>
            </div>
            <button className='link-mobile' onClick={()=> setClick(!click)}>
                {click ? <CloseIcon/> : <MenuIcon/>}
            </button>
        </div>
    </div>
  )
}

export default Navbar