import React, { useState } from 'react'
import "./mobile.scss"
import "./comingup.scss"
import advocacy from "../../images/advocacy.jpg"
import ahi from "../../images/ahi.jpg"
import Edu_1 from "../../images/Edu_1.jpg"
import Edu_2 from "../../images/Edu_2.jpg"
import Edu_3 from "../../images/Edu_3.jpg"
import Health_1 from "../../images/Health_1.jpg"
import Health_2 from "../../images/Health_2.jpg"
import { Link } from 'react-router-dom'

const ComingUp = () => {

  const [selected,setSelected] = useState(-1)
  
const comH = [
{
  id: 1,
  img: advocacy,
  title: "Advocacy: DEVCOMS",
  details: "Development Communications (DEVCOMS) Network is a media development, not for profit organization, dedicated to mobilizing, promoting and galvanizing science, public health and social sector journalism to ensure resultant and sustainable human development. The focus areas are climate change, education, family planning, investigative journalism, maternal and child health, population, public health journalism and science."
},
{
  id: 2,
  img: ahi,
  title: "Health: Action Health Incorporated (AHI)",
  details: "Action Health Incorporated (AHI) is a non-profit, non-governmental organisation dedicated to improving the health of Nigerian adolescents and to ensure their successful transition to healthy and productive adulthood."
},
{
  id: 3,
  img: Edu_1,
  title: "Education: Aunty Bose Nursery & Primary School, Sagamu, Ogun State",
  details: "It offers a pre-primary school to children before they begin compulsory education at primary school. It also offers a primary education to children who are four to eleven years of age."
},
{
  id: 4,
  img: Health_2,
  title: "Health: Hope For HIV/AIDs International ",
  details: "Hope for HIV/AIDS International is a non-profit organization dedicated to providing education, mobilization and health services to vulnerable children and people living with HIV/AIDS in the slums of Lagos. Its activities also centre on Survivors of Violence against Children (SVAC), At Risk Adolescent Girls and Young Women (AGYW), Adolescents Girls and Boys (9-14 years)."
},
{
  id: 5,
  img: Edu_2,
  title: "Education: Nigerian Red Cross Orphanage, Makoko, Yaba ",
  details: "Is a charity organization. The Orphanage home takes care of children who are abandoned, motherless or orphaned. They work alongside the police and the Ministry of Social Welfare to get children. The orphanage home caters for abused, motherless, and missing children. It also caters for abandoned babies."
},
{
  id: 6,
  img: Edu_3,
  title: "Education: Hope Vision Schools",
  details: "Hope Vision School is a community based project located in Bariga, a high populated area in the city of Lagos. It is a school founded for the children whose parents cannot afford to pay school fees. The purpose of the project is to bring children back to school and help them to break the circle of poverty. Schooling and a daily meal will be provided free of charge. The school is funded by charity and philanthropists."
},
]  

  return (
    <div className='coming'>
      <div className="titleUp">
        Coming Up
        <h2>Host Projects</h2>
      </div>
      <div className="comP">
        
      {comH.map((comH,index)=>(
        
        <div className={selected === index ? "comHolder active" : "comHolder"}  key={comH.id} onClick={ () => {setSelected(index); console.log(comH)}}>
        <img src={comH.img} alt='' />            
        <h3>{comH.title}</h3>
        <p>{comH.details}</p>
      </div>
      ))}
        
        
      </div>
    </div>
  )
}

export default ComingUp