import React from 'react'
import "./we.scss"

const We = () => {

const icye = [
  {

    id: 1,
    icon: "",
    title: "STIMULATE INTERACTION",
    details: "We provide enriching opportunities for members to interact with people worldwide, transcend barriers, exchange cultures, and examine global issues."

  },
  {

    id: 2,
    icon: "",
    title: "PROVIDE FUN LEARNING OPPORTUNITIES",
    details: "Our member-oriented activities provide fun learning opportunities for volunteers and members which allows them to help each other, while learning themselves."

  },
  {

    id: 3,
    icon: "",
    title: "ELIMINATE CULTURAL BARRIERS",
    details: "We create exciting ways for members and volunteers to get involved and make a difference beyond borders regardless of cultural differences."

  },
]

  return (
    <div className='we'>
        <div className="weTitle">
            ICYE's
            <h1>We</h1>
        </div>
        <div className="cardTitle">
          {icye.map(icye=>(
            <div className="carDetails" key={icye.id}>
              <div className="cardTop">
                <div className="cardtopLeft">{icye.icon}</div>
                <div className="cardtopright">{icye.title}</div>
              </div>
              <div className="cardContent">
                <p>{icye.details}</p>
              </div>
            </div>
          ))}
          
        </div>
    </div>
  )
}

export default We