import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import Footer from "./components/footer/Footer";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import IcyeNig from "./pages/icyenig/IcyeNig";
import Nigeria from "./pages/nig/Nigeria";
import Programmes from "./pages/programmes/Programmes";
import Register from "./pages/register/Register";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Admin from "./admin/Admin/Admin";
import Post from "./admin/post/Post";

function App() {

  const Layout =()=>{
    return(
      <div className="contain">
        <Outlet/>
        <Footer/>
      </div>
    )
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children:[
        {
          path: "/",
          element: <Home/>
        },
        {
          path: "/About",
          element: <About/>
        },
        {
          path: "/IcyeNig",
          element: <IcyeNig/>
        },
        {
          path: "/Nigeria",
          element: <Nigeria/>
        },
        {
          path: "/Programmes",
          element: <Programmes/>
        },
        {
          path: "/Register",
          element: <Register/>
        },
        {
          path: "/Contact",
          element: <Contact/>
        },
      ]
    },
    {
      path: "/Admin",
      element: <Admin/>
    },
    {
      path: "/Post",
      element: <Post/>
    },
    
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
