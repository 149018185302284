import React from 'react'
import { Link } from 'react-router-dom'
import "./footer.scss"
import "./mobile.scss"

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footerLeft">
        <div className="abi">
          <h2>Who We Support</h2>
          <p>We support an enormous network of schools, NGOs, orphanages, hospitals, clinics, community development projects, national parks, and more by providing essential volunteers, conducting 
          special projects, and fostering a global understanding.</p>
          <div className="signForm">
            <input type="text" placeholder='Enter your email' />
            <button>Subscrib Now</button>
        </div>
        </div>
      </div>
      <div className="footerRight">
        <div className="fLeft">
        <div className="menuFooter">
        <h2>Who We Support</h2>
          <ul>
          <li><Link to='/' className='links'>Home</Link></li>
          <li><Link to='/About' className='links'>who we are</Link></li>
           <li><Link to='/IcyeNig' className='links'>icye Nig</Link></li>
          <li><Link to='/Nigeria' className='links'>country profile</Link></li>
          <li><Link to='/Programmes' className='links'>program</Link></li>
          <li><Link to='/' className='links'>partners</Link></li>
          <li><Link to='/' className='links'>projects</Link></li>
          <li><Link to='/Contact' className='links'>Contact Us</Link></li>
          </ul>
        </div>
        <div className="menuFooter">
        <h2>Partners</h2>
          <ul>
          <li>COLOMBIA</li>
          <li>COSTA RICA</li>
           <li>BRAZIL</li>
          <li>AUSTRIA</li>
          <li>GERMANY</li>
          <li>FRANCE</li>
          <li>And more here</li>
          </ul>
        </div>
        <div className="contactFooter">
          <h2>Contact Us</h2>
          <p>OUR POSTAL ADDRESS IS:<br/>
          ICYE NIGERIA<br/>P. O. BOX 1025, Sabo – Yaba, Lagos.
          </p>
          <p>CONTACT PERSON:<br/>
          ADEOLU ONAMADE<br/>
          318 Herbert Macaulay Street, Sabo-Yaba, Lagos.<br/>
          FAX: (1) 530579 5543 (USA)</p>
        </div>
        </div>
        <div className="fRight">
        © 2006-2023. All Rights Reserved, Copyright 2023 - Nigeria.
        </div>
        
      </div>
      
      
    </div>
  )
}

export default Footer