import React from 'react'
import "./adm.scss"

const Admin = () => {
  return (
    <div className='admin'>
        <div className="login">
            <div className="title">Admin Login</div>
            <div className="loginFields">
                <div className="textBox">
                    <div className="left">icon</div>
                    <div className="right">
                        <input type='text' placeholder='Username' name="name" />
                    </div>
                </div>
                <div className="textBox">
                    <div className="left">icon</div>
                    <div className="right">
                        <input type='password' placeholder='Password' name="password" />
                    </div>
                </div>
                <button>Login</button>
            </div>
        </div>
    </div>
  )
}

export default Admin