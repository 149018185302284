import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./post.scss"

const Post = () => {
  const [value, setValue] = useState('');
  console.log(value)
  return (
    <div className="post">
        <div className="contentfram">
            <div className="content">
                <input type='text' placeholder='Title' name='title'/>
                <div className="edtcontent">
                <ReactQuill theme="snow" className='editor' value={value} onChange={setValue} />
                </div>
            </div>
            <div className="leftmenu">
                <div className="items">
                    <h1>Publish</h1>
                    <span><b>Status:</b> Draf</span>
                    <span><b>Visibility:</b> Public</span>
                    <input style={{displa:"none"}} type="file" id="file" />
                    <label htmlFor="file">Upload Image</label>
                    <div className="button">
                        <button>Save to Draf</button>
                        <button>Update</button>
                    </div>
                </div>
                <div className="items">
                    <h1>Category</h1>
                    <div className="cat">
                    <input type="radio" name="cat" value="Health" id="Health" />
                    <label htmlFor="Health">Health</label>
                    </div>
                    <div className="cat">
                    <input type="radio" name="cat" value="Education" id="Education" />
                    <label htmlFor="Health">Education</label>
                    </div>
                </div>
            </div>
        </div>
        <div className="contentable">
            content table
        </div>
        
    </div>
  )
}

export default Post