import React from 'react'
import "./nigeria.scss"
import "./mobile.scss"
import Navbar from '../../components/navbar/Navbar'

const Nigeria = () => {
  return (
    <div className='nig'>
        <div className="contactBanner">
            <Navbar/>
            <div className="conTitile">
             <h2>Nigeria Is The Pride Of Africa</h2>
            </div>
        </div>
        <div className="ANig">
        <div className="iceyLeft">
                <h2>About Nigeria</h2>
                <p>
                The country is known for its rich cultural heritage, with notable landmarks 
                such as the Nok Terracotta sculptures, the Osun-Osogbo Sacred Grove, and the 
                Zuma Rock. The country has also produced notable individuals in various fields 
                such as literature, music, sports, and politics, including Nobel laureate Wole Soyinka, 
                musician Fela Kuti, footballer Jay-Jay Okocha, and former presidents Olusegun Obasanjo 
                and Goodluck Jonathan.
                </p>
                <p>
                The Federal Republic of Nigeria is a Federal Constitutional 
                Republic comprising thirty six states and one Federal 
                Capital Territory. The Country is located in West Africa 
                and shares land borders with the Republic of Benin in the 
                west, Chad and Cameroon in the east and Niger in the north. 
                Its coast lies on the Gulf of Guinea, a part of the Atlantic 
                Ocean in the South. The capital city is Abuja. The three 
                largest and most influential ethnic groups in Nigeria are 
                the Hausa, Igbo and Yoruba.
                </p>
                <p>
                The people of Nigeria have an extensive history and 
                archaeological evidence shows that human habitation of the 
                area dates back to at least 9000 BC. The Benue-Cross River 
                area is thought to be the original homeland of the Bantu 
                migrants who spread across most of central and southern 
                Africa in waves between the 1st millennium BC and the 2nd 
                millennium.</p>
                <p>Nigeria is the most populous country in Africa, the eight 
                    most populous country in the world and the most populous 
                    country in the world in which the majority of the 
                    population is black. It is listed among the “Next Eleven” 
                    economies and is a member of the Commonwealth of Nations. 
                    The economy of Nigeria is one of the fastest growing in the 
                    world with the IMF projecting a growth of 8.3% in 2009. 
                    Nigeria is the second largest economy in Africa and is a 
                    regional power that has predominant influence over others 
                    in West Africa.</p>
                    <p>Nigeria is famous for its English language literature and 
                        its popular music. Since the 1990s the Nigerian movie 
                        industry sometimes called “Nollywood” has emerged as a 
                        fast-growing cultural force all over the continent. 
                        All over the country, and even increasingly in the 
                        conservative north, western music, dresses and movies are 
                        ever popular.
                </p>
            </div>
            <div className="iceyRight">
                <p>
                Soccer (often referred to as "football") is extremely popular throughout the 
                country and especially among the youth, both field soccer and professional 
                international soccer, has developed into a cult of unity and division. Supporters 
                of Manchester United, Arsenal football club, Liverpool and Chelsea often segregate 
                beyond the traditional tribal and even religious divide to share their common cause 
                in English Premier League teams.
              </p>
              <p>The Nigerian national football team nicknamed the Super Eagles, is the national 
                team of Nigeria and is controlled by the Nigeria Football Federation (NFF). 
                According to the FIFA World Rankings, Nigeria ranks 22nd and holds the third 
                highest place among the African nations behind Cameroon (11th) and Côte d'Ivoire (16th). 
                The highest position ever reached on the ranking was 5th in April 1994.
              </p>
              <p>Nigerian food embellishes a rich blend of traditionally African carbohydrates such as 
                Yam and Cassava as well as vegetable soups made from native green leaves. Praised by 
                Nigerians for the strength it gives, Garri is a powdered Cassava Grain that can be readily 
                eaten as a meal and is quite cheap. Yam is either fried in oil or pounded to make a Mashed 
                Potato like Yam pottage. Nigerian beans, quite different from green peas, is widely popular. 
                Meat is also popular and Nigerian Suya, a barbecue like method of roasting meat, is a well 
                known delicacy. Bush meat, meat from wild game like Dear and Giraffes and is also popular. 
                Fermented palm products is used to make traditional liquor, Palm Wine, as is fermented Cassava.
              </p>
              <p>
              The music of Nigeria includes many kinds of folk and popular music, some of which are known 
              worldwide. Traditional musicians use a number of diverse instruments such as the Gongon drums. 
              Other traditional cultural expressions are found in the various masquerades of Nigeria, 
              such as the Eyo masquerades, the Ekpe and Ekpo Masquerades of the Efik/Ibibio/Annang/Igbo peoples 
              of coastal south-eastern Nigeria.</p>
            </div>
        </div>
    </div>
  )
}

export default Nigeria