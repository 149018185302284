import React from 'react'
import "./partners.scss"
import { Component } from "react";
import Slider from "react-slick";
import Austria from "../../images/Austria.png"
import france from "../../images/france.png"
import germany from "../../images/germany.png"
import ghana from "../../images/ghana.png"
import italy from "../../images/italy.png"
import kenya from "../../images/kenya.png"
import mexico from "../../images/mexico.png"
import morocco from "../../images/morocco.png"
import switzerland from "../../images/switzerland.png"
import Uk from "../../images/Uk.png"
import sweden from "../../images/sweden.png"
import usa from "../../images/usa.png"

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className='partners'>
        <div className="weTitle">
            ICYE's
            <h1>Partners</h1>
        </div>
        <Slider {...settings} style={{display:'flex'}}>
          <div className="flags">
              <img src={Austria} alt=""/>
              <h3>Austria</h3>
          </div>
          <div className="flags">
              <img src={france} alt=""/>
              <h3>france</h3>
          </div>
          <div className="flags">
              <img src={usa} alt=""/>
              <h3>United states</h3>
          </div>
          <div className="flags">
              <img src={Uk} alt=""/>
              <h3>United Kingdom</h3>
          </div>
          <div className="flags">
              <img src={italy} alt=""/>
              <h3>italy</h3>
          </div>
          <div className="flags">
              <img src={germany} alt=""/>
              <h3>germany</h3>
          </div>
          <div className="flags">
              <img src={ghana} alt=""/>
              <h3>ghana</h3>
          </div>
          <div className="flags">
              <img src={sweden} alt=""/>
              <h3>sweden</h3>
          </div>
          <div className="flags">
              <img src={switzerland} alt=""/>
              <h3>switzerland</h3>
          </div>
          <div className="flags">
              <img src={morocco} alt=""/>
              <h3>morocco</h3>
          </div>
          <div className="flags">
              <img src={mexico} alt=""/>
              <h3>mexico</h3>
          </div>
            
          
        </Slider>
      </div>
    );
  }
}