import React from 'react'
import "./heritage.scss"
import "./mobile.scss"
import pix01 from "../../images/pix01.jpg"
import pix02 from "../../images/pix02.jpg"
import pix03 from "../../images/pix03.jpg"
import pix04 from "../../images/pix04.jpg"
import pix05 from "../../images/pix05.jpg"
import pix06 from "../../images/pix06.jpg"
import pix07 from "../../images/pix07.jpg"
import pix08 from "../../images/pix08.jpg"
import pix09 from "../../images/pix09.jpg"

const Heritage = () => {
  return (
    <div className='heritages'>
        <div className="galleryBox">
            <div className="galleryTitle">
                Project
                <h1>Heritage</h1>
            </div>
            <div className="pixcontaner">
                <div className="pic">
                    <div className="titlePix">Heritage Project</div>
                    <img src={pix01} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Heritage Project</div>
                    <img src={pix02} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Heritage Project</div>
                    <img src={pix03} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Heritage Project</div>
                    <img src={pix05} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Heritage Project</div>
                    <img src={pix06} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Heritage Project</div>
                    <img src={pix07} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Heritage Project</div>
                    <img src={pix08} alt="" />
                </div>
                <div className="pic">
                    <div className="titlePix">Heritage Project</div>
                    <img src={pix09} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Heritage