import React from 'react'
import "./home.scss"
import "./mobile.scss"
import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import Navbar from "../../components/navbar/Navbar"
import sl1 from "../../images/sl1.jpg"
import sl2 from "../../images/sl2.jpg"
import sl3 from "../../images/sl3.jpg"
import pix01 from "../../images/pix01.jpg"
import whoweare from "../../images/whoweare.png"
import HeroTitle from '../../components/heroTitle/HeroTitle'
import Who from '../../components/who/Who';
import We from '../../components/we/We';
import Become from '../../components/become/Become';
import Partners from '../../components/partners/Partners';
import Subscrib from '../../components/subcrib/Subscrib';
import ComingUp from '../../components/comingUp/ComingUp';
import Gallery from '../../components/gallery/Gallery';
import Heritage from '../../components/heritage/Heritage';


const Home = () => {
  return (
    <div className='home'>
      <div className="sliderHero">
      <HeroSlider
      height={"100vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 500,
        slidingDelay: 100,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide)
      }}
    >

<Slide
        shouldRenderMask
        label="HERITAGE PROJECT"
        background={{
          backgroundImageSrc: pix01
        }}
      />

      <Slide
        shouldRenderMask
        label="WHO WE SUPPORT"
        background={{
          backgroundImageSrc: sl3
        }}
      />

      <Slide
        shouldRenderMask
        label="ELIMINATE CULTURAL BARRIERS"
        background={{
          backgroundImageSrc: sl2
        }}
      />

      <Slide
        shouldRenderMask
        label="PROVIDE FUN LEARNING OPPORTUNITIES"
        background={{
          backgroundImageSrc: sl1
        }}
      />

      <Slide
        shouldRenderMask
        label="STIMULATE INTERACTION"
        background={{
          backgroundImageSrc: whoweare
        }}
      />



      <MenuNav />
      </HeroSlider>
      <div className="contain">
        <Navbar/>
        <HeroTitle/>
      </div>
      </div>
      <div className="containerBg">
        <div className="leftBg">
          <img src={whoweare} alt="whoweare" />
        </div>
        <div className="rightBg">
          <Who/>
        </div>
      </div>
      <div className="icyeWe">
        <We/>
      </div>
      <div className="become">
        <Become/>
      </div>
      <div className="gallery">
        <Gallery/>
      </div>
      <div className="partners">
        <Partners/>
      </div>
      <div className="gallery">
        <Heritage/>
      </div>
      <div className="subscrib">
        <Subscrib/>
      </div>
      <div className="comUp">
        <ComingUp/>
      </div>
    </div>
  )
}

export default Home